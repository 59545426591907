import React, { useState, useEffect } from "react"
import { useStaticQuery, graphql } from "gatsby"
import CareerItem from "./CareerItem"

const CareerIndex = () => { 
    const { allPostsData } = useStaticQuery(
        graphql`
          query {
            allPostsData : allPrismicJobs {
                edges {
                node {
                    uid
                    data {
                    title {
                        text
                        html
                    }
                    short_description
                    image {
                        gatsbyImageData(layout: FULL_WIDTH)
                    }
                    icon_image {
                        fixed(height: 72, width: 72) {
                        srcWebp
                        }
                    }
                    job_location
                    author {
                        document {
                        ... on PrismicPostAuthors {
                            id
                            data {
                            author_name {
                                text
                                html
                            }
                            designation
                            image {
                                fixed(width: 65, height: 65) {
                                srcSetWebp
                                srcWebp
                                }
                            }
                            }
                        }
                        }
                    }
                    }
                }
                }
            }
          }
        `
    )
    const allPosts = allPostsData.edges
    const showRecords = 3
    const [list, setList] = useState([...allPosts.slice(0, showRecords)])
    const [loadMore, setLoadMore] = useState(false)
    const [hasMore, setHasMore] = useState(allPosts.length > showRecords)

    const handleLoadMore = () => {
        setLoadMore(true)
    }
    useEffect(() => {
        if (loadMore && hasMore) {
            const currentLength = list.length
            const isMore = currentLength < allPosts.length
            const nextResults = isMore
                ? allPosts.slice(currentLength, currentLength + showRecords)
                : []
            setList([...list, ...nextResults])
            setLoadMore(false)
        }
    }, [loadMore, hasMore])

    useEffect(() => {
        const isMore = list.length < allPosts.length
        setHasMore(isMore)
    }, [list])
    return (
            <div className="max-w-7xl mx-auto px-4 md:pb-20 pb-16">
                <div className="relative border-t border-red mt-10">
                    {list.map((article) => (
                        <CareerItem key={article.id} data={article} />
                    ))}
                </div>
                <div className="flex items-center justify-center md:mt-20 mt-10">
                    {hasMore ? (
                        <button onClick={handleLoadMore} className="bg-white shadow-lg font-medium sm:text-xl text-base py-3 px-7 text-blue rounded-full no-underline mt-4 tracking-wide uppercase inline-block btn1">Load More</button>
                    ) : (
                        <p className="bg-white shadow-lg font-medium sm:text-xl text-base py-3 px-7 text-blue rounded-full no-underline mt-4 tracking-wide uppercase inline-block btn1">No more results</p>
                    )}
                </div>
            </div>
    )
}
export default CareerIndex       
