import React from 'react';

import { FormButton } from "../../frontend-shared/components";

import Link from "./Link";
import RichOrText from "./RichOrText";

const DenseSplitSection = ({
  title,
  description,
  descriptionProps,

  button_text,
  button_link,

  poster,
}) => (
  <div className="grid grid-cols-1 md:grid-cols-2 rounded-2xl overflow-hidden flex flex-col">
    <div className="bg-gradient p-8 text-white flex flex-col justify-center gap-8 md:border-r-2 md:border-[#FB2661]">
      <h3 className="text-2xl lg:text-4xl 2xl:text-5xl font-medium">
        {title}
      </h3>
      <div className="text-lg md:text-xl">
        <RichOrText {...descriptionProps} content={description} />
      </div>
    </div>
    <div className="flex flex-col items-center relative md:border-l-2 md:border-[#FB2661]">
      <img
        alt={title}
        className="m-0 h-full w-full"
        src={poster?.fluid?.srcSetWebp?.split(',').at(-1)}
      />
      {button_text && (
        <Link
          className="no-underline absolute bottom-8"
          href={button_link}
        >
          <FormButton color="secondary" size="lg">
            {button_text}
          </FormButton>
        </Link>
      )}
    </div>
  </div>
);

const SparseSplitSection = ({
  title,
  description,
  descriptionProps = {},
  poster,
  button_text,
  button_link,
  textBeforeImageOnMobile,
}) => {
  const mobileImage = (
    <div className="flex flex-col md:hidden">
      <img
        alt={poster?.alt}
        className="block rounded-xl flex"
        src={poster?.fluid?.srcWebp}
        srcSet={poster?.fluid?.srcSetWebp}
      />
    </div>
  );
  return (
    <div className="flex flex-col gap-16 md:grid md:grid-cols-2 justify-center">
      <div className="flex flex-col items-start">
        <div className="flex flex-col gap-2 md:gap-4 md:max-w-lg">
          <h2 className="text-blue md:text-5xl md:text-4xl text-2xl font-medium md:mb-8 mb-5 tracking-tighter">
            {title}
          </h2>
          {!textBeforeImageOnMobile && mobileImage}
          <div className="text-blue text-xl">
            <RichOrText {...descriptionProps} content={description} />
          </div>
          {textBeforeImageOnMobile && mobileImage}
          <div />
          <div />
          <div className="flex flex-col items-start md:items-center">
            <Link className="no-underline" href={button_link}>
              <FormButton
                className="w-36"
                color="secondary"
                size="lg"
              >
                {button_text}
              </FormButton>
            </Link>
          </div>
        </div>
      </div>
      <div className="flex-col hidden md:flex">
        <img
          alt={poster?.alt}
          className="rounded-xl"
          src={poster?.fluid?.srcWebp}
          srcSet={poster?.fluid?.srcSetWebp}
        />
      </div>
    </div>
  );
};

const SplitSection = ({ dense, ...rest }) => (
  <div className="px-4 xl:px-0 p-16 max-w-7xl mx-auto">
    {dense ? (
      <DenseSplitSection {...rest} />
    ) : (
      <SparseSplitSection {...rest} />
    )}
  </div>
);

export default SplitSection;
