import * as React from "react"
import { useStaticQuery, graphql } from "gatsby"
import moment from 'moment';
import Link from '../Elements/Link';

const RecentJobs = ({ title }) => {
  const { allPosts } = useStaticQuery(
    graphql`
      query {
      allPosts: allPrismicJobs(
        sort: {fields: last_publication_date, order: DESC}
        limit: 3
      ) {
        edges {
        node {
          uid
          data {
          title {
            text
            html
          }
          heading {
            text
            html
          }
          job_location
          icon_image {
            fixed(width: 72, height: 72) {
            srcWebp
            }
          }
          image {
            fixed {
              srcSet
              srcWebp
            }
            }
          }
          last_publication_date
        }
        }
      }
      }
    `
  )
  return (
    <div className="md:py-20 pt-16 pb-4">
      <div className="max-w-7xl mx-auto px-4 ">
        <h2 className="lg:text-7xl md:text-6xl text-3xl md:mb-16 mb-8 text-blue font-medium tracking-tighter">
          {title?.text}
        </h2>
        <div className="grid lg:grid-cols-3 grid-cols-1 lg:gap-4 gap-12">
          {allPosts && allPosts.edges && allPosts.edges.map((item, index) => {
            const { title, heading, job_location, icon_image } = item.node.data
            const publish_date = item.node.last_publication_date
            return (
              <div key={index}>
                <h4 className="font-medium text-3xl text-blue mb-3">0{index + 1}</h4>
                <div className="bg-white shadow-xl rounded-2xl lg:py-10 py-8 xl:px-12 sm:px-8 px-4">
                  <div className="flex flex-row justify-start gap-3 items-center mb-5">
                    {icon_image && icon_image.fixed && icon_image.fixed.srcWebp && (
                      <img loading="lazy" width={65} height={65} src={icon_image.fixed.srcWebp} alt={title && title.text} className="mb-0" />
                    )}
                    <div>
                      <h4 className="text-blue font-bold sm:text-lg text-base mb-1">
                        <Link href={`/careers/${item.node.uid}`} className="text-blue no-underline font-bold font-inter text-lg">
                          {title?.text}
                        </Link>
                      </h4>
                      <h6 className="text-base">{job_location}</h6>
                    </div>
                  </div>
                  <div>
                    <h3 className="text-blue font-bold text-xl mb-3">{heading && heading.text}</h3>
                    <p className="text-gray-400 font-normal text-base mb-0">Posted on {moment(publish_date).format('MMM D, YYYY')}</p>
                  </div>
                  <div>
                  </div>
                </div>
              </div>
            )
          })}
        </div>
      </div>
    </div>
  )
}
export default RecentJobs
