import * as React from "react"
import moment from 'moment';
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import Link from "../Elements/Link"


const CareerItem = ({ data }) => {
  const { title, short_description, image, author, icon_image, job_location } = data.node.data
  const publish_date = data.node.last_publication_date
  const uid = data.node.uid
  return (
    <div className="flex lg:flex-row flex-col items-center border-b lg:py-20 py-10 border-blue-900">
      <div className="lg:w-8/12 w-full 2xl:pr-32">
        <div className="flex flex-row justify-start gap-4 items-center mb-6">
        {icon_image && icon_image.fixed && icon_image.fixed.srcWebp && (
            <img loading="lazy" width={65} height={65} src={icon_image.fixed.srcWebp} alt={title && title.text} className="mb-0" />
          )}
          <div>
            <h4 className="text-blue font-bold text-xl mb-1 font-inter"><Link href={`/careers/${uid}`} className="text-blue no-underline font-inter font-bold">{title && title.text}</Link></h4>
            <h6 className="font-inter text-blue">{job_location}</h6>
          </div>
        </div>
        <div className="w-10/12">
          <h3 className="text-blue font-bold md:text-4xl text-2xl mb-5"><Link href={`/careers/${uid}`} className="text-blue font-bold md:text-4xl text-2xl no-underline">{title && title.text}</Link></h3>
          <p className="text-blue text-lg font-normal ">{short_description && short_description}</p>
          <div className="lg:flex gap-4 items-center hidden">
            <p className="text-gray-400 font-normal text-base m-0 ">Posted on {moment(publish_date).format('MMM D, YYYY')} by</p>
            {author && (
              <div className="flex gap-4 items-center">
                <img loading="lazy" src={author?.document?.data?.image && author.document.data.image.fixed.srcWebp} srcSet={author?.document?.data?.image && author.document.data.image.fixed.srcSetWebp} width={36} height={36} className='mb-0' />
                <div>
                  <p className="font-bold text-sm text-blue mb-0">{author?.document?.data?.author_name && author.document.data.author_name.text}</p>
                  <p className="text-sm text-blue mb-0">{author?.document?.data?.designation && author.document.data.designation}</p>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
      <div className="lg:w-4/12 w-full">
        <Link href={`/careers/${uid}`}><GatsbyImage image={getImage(image)} alt={title && title.text} className="w-full rounded-2xl" /></Link>
        <div className="flex gap-4 items-start flex-col lg:hidden mt-6">
          <p className="text-gray-400 font-normal text-base m-0 ">Posted on {moment(publish_date).format('MMM D, YYYY')}</p>
          {/* {author && (
            <div className="flex gap-4 items-center mb-6 hidden">
              <img loading="lazy" src={author?.document?.data?.image && author.document.data.image.fixed.srcWebp} srcSet={author?.document?.data?.image && author.document.data.image.fixed.srcSetWebp} width={36} height={36} className='mb-0' />
              <div>
                <p className="font-bold text-lg text-blue mb-0">{author?.document?.data?.author_name && author.document.data.author_name.text}</p>
                <p className="text-base text-blue mb-0">{author?.document?.data?.designation && author.document.data.designation}</p>
              </div>
            </div>
          )} */}
        </div>
      </div>
    </div>
  )
}
export default CareerItem       
