import * as React from "react"
import { useStaticQuery, graphql } from "gatsby"
import { StaticImage } from 'gatsby-plugin-image';

import CustomerExperience from '../Elements/CustomerExperience';
import SplitSection from '../Elements/SplitSection';

import RecentJobs from "./Recentjobs"
import CareerIndex from "./CareerIndex";

const HeroSection = ({
  slice: {
    primary: {
      heading,
      latest_openings_heading,
      why_apply_heading,
      why_apply_content,
      why_apply_image,
    },
  },
}) => {
  const { ShipmentLeft, shipmentleftmobile } = useStaticQuery(
    graphql`
      query {
        ShipmentLeft: file(relativePath: {eq: "shipment-left.png"}) {
            childImageSharp {
                fixed(width: 480, height:380) {
                    srcWebp
                    srcSetWebp
                }
            }
        }

        shipmentleftmobile: file(relativePath: {eq: "shipment-left.png"}) {
            childImageSharp {
                fixed(width: 480, height:380) {
                    srcWebp
                    srcSetWebp
                }
            }
        }

      }
    `
  );
  return (
    <>
      <div className="bg-gradient sm:pt-32 pt-28 pb-0">
        <div className="w-full grid md:grid-cols-2 grid-cols-1 flex justify-between items-center lg:gap-28 md:gap-10 relative">
          <div className="">
            <StaticImage src="../../assets/images/careers/career_img.png" className="order-last md:block" alt="" width="700" height="480" />
          </div>
          <div className="xl:pl-12 md:pl-10 pl-6 pr-0 order-first md:ml-auto">
            <h1 className="text-white xl:text-7xl lg:text-6xl sm:text-5xl text-4xl font-medium career_tlt md:w-full sm:w-3/4 md:mb-6 mb-12">Join the last mile revolution!</h1>
            <h5 className="text-lg text-green-carriyo mb-3">CAREERS</h5>
          </div>
        </div>
      </div>
      <div className="background1">
        <RecentJobs title={latest_openings_heading} />
        <SplitSection
          dense
          title={why_apply_heading.text}
          description={why_apply_content?.text}
          poster={why_apply_image}
        />
        <CareerIndex />
      </div>
      <div className="bg-gradient xl:py-32 md:py-24 py-16 text-white">
        <CustomerExperience items="clients" />
      </div>
    </>
  )
}

export default HeroSection;


export const query = graphql`
  fragment careersHeroSlice on PrismicCareersDataBodyHero {
    id
    slice_type
    primary {
      heading {
          text
      }
      latest_openings_heading {
        text
        html
      }
      why_apply_heading {
          text
          html
      }
      why_apply_content {
        text
      }
      why_apply_image {
        fluid(maxWidth: 800, maxHeight: 800) {
          srcSetWebp
        }
      }
    }
  }
`
